.card {
    &.card-property {
        border: 0;
        border-radius: 4px;
        overflow: hidden;
        position: relative;

        .tags {
            position: absolute;
            top: 15px;
            right: 30px;
            z-index: 9;
            display: flex;

            .tag {
                //font-family: $poppinsregular;
                font-size: 12px;
                line-height: 8px;
                border-radius: 5px;
                padding: 10px 20px;
                margin-left: 10px;
            }

            .black {
                color: $color-1;
                background-color: $color-2;
            }
    
            .yellow {
                color: $color-2;
                background-color: #D88B39;
            }
        }

        .property-images {
            .swiper-container {
                padding: 0;
            }

            .swiper-slide {
                .image {
                    height: 400px;
                    width: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    @include media-breakpoint-down(sm) {
                        height: 230px;
                    }
                }
            }

            .swiper-pagination {
                display: block;
                
                .swiper-pagination-bullet {
                    width: 8px;
                    height: 8px;
                    background-color: $color-0;
                    opacity: 0.7;
                    margin: 2px 10px;
                    transition: 0.2s;
        
                    &.swiper-pagination-bullet-active {
                        opacity: 1;    
                    }
                }
            }

            .swiper-button-next,
            .swiper-button-prev {
                width: 50px;
                background-color: rgba(0, 0, 0, 0.4);
                height: 100%;
                margin: 0;
                top: 0;
                opacity: 0;
                transition: 0.2s;
                border-radius: 0;
                border: 0;

                &:after {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    background-color: transparent;
                    border-top: 2px solid $color-0;
                    border-right: 2px solid $color-0;
                    top: 50%;
                    margin-top: -8px;
                    position: absolute;
                }
            }

            .swiper-button-prev {
                left: 0;

                &:after {
                    transform: rotate(-135deg);
                    left: 50%;
                    margin-left: -5px;
                }
            }

            .swiper-button-next {
                right: 0;
                left: auto;

                &:after {
                    transform: rotate(45deg);
                    left: 50%;
                    margin-left: -10px;
                }
            }
            
            &:hover {
                .swiper-button-next,
                .swiper-button-prev {
                    opacity: 1;
                }
            }
        }

        .description {
            color: $color-2;
            padding: 40px 50px 0 20px;
            text-align: left;

            @include media-breakpoint-down(sm) {
                padding: 30px 50px 20px 20px;
            }

            .info {
                display: flex;

                .text-5 {
                    color: #7B7B7B;
                    margin-bottom: 20px;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-height: 20px;
                    max-height: 60px;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }

                &.top-info {
                    align-items: center;
                    justify-content: space-between;

                    .block-title {
                        text-align: left;

                        .title-small {
                            font-size: 14px;
                            display: block;
                            color: #D88B39;
                        }
                    }
                    
                    .icons {
                        text-align: right;

                        .icon {
                            max-height: 22px;
                            cursor: pointer;
                            margin-bottom: 5px;
    
                            &.filled ,
                            &.linear {
                                opacity: 1;
                                width: auto;
                                transition: 0.2s;
                            }
                        }

                        .text {
                            color: #A5A5A5;
                        }
                    }
                }

                &.middle-info {
                    justify-content: space-between;
                    max-width: 370px;
                    margin-bottom: 30px;

                    .feature {
                        .icon {
                            margin-right: 7px;
                            max-height: 18px;
                            vertical-align: text-top;
                        }

                        .info-feature {
                            font-size: 14px;
                            font-family: $poppinsregular;
                        }
                    }
                }

                &.bottom-info {
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    .text {
                        color: #5A5A5A;
                        margin: 0;
                    }

                    .price {
                        display: block;
                        font-family: $poppinsregular;
                        font-size: 20px;
                        margin-right: 20px;
                    }

                    .priceOld {
                        display: block;
                        font-family: $poppinsregular;
                        font-size: 14px;
                        margin-right: 20px;
                        text-decoration: line-through;
                        color: #A5A5A5;
                    }

                    .btn {
                        .icon {
                            vertical-align: text-bottom;
                            margin-right: 10px;
                        }
                    }

                    .icons {
                        text-align: right;

                        .icon {
                            margin-bottom: 5px;
                        }
                    }
                }
            }

            .features {
                padding: 25px 10px 10px;

                .text {
                    color: $color-10;
                }

                .list {
                    list-style: none;
                }
            }

            .actions {
                .btn {
                    display: block;

                    .icon {
                        height: 15px;
                        vertical-align: middle;
                        margin-right: 10px;
                    }

                    &:first-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    &.card-help {
        background-color: $color-1;
        border-color: $color-1;
        padding: 100px 50px;
        border-radius: 10px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .block-info {
            text-align: center;
        }
    }

    &.card-main-property {
        overflow: hidden;
        position: relative;
        border-radius: 0;
        border: 0;

        .property-content {
            height: calc(100vh - 80px);
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            @include media-breakpoint-down(sm) {
                height: 520px;
                background-position: center top;
                background-size: 600px;
            }

            .description {
                color: $color-0;
                padding: 20px 70px 20px 110px;
                max-width: max-content;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.7);
                border-radius: 0 20px 0 0;

                @include media-breakpoint-down(sm) {
                    color: #1D2832;
                    border-radius: 0;
                    background-color: #F8F8F8;
                    padding: 40px 20px 80px;
                    text-align: center;
                    max-width: 100%;
                }
    
                .info {
                    display: flex;
    
                    &.top-info {
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 10px;
    
                        @include media-breakpoint-down(sm) {
                            justify-content: center;
                        }

                        .block-title {
                            .title-10 {
                                color: $color-0;

                                @include media-breakpoint-down(sm) {
                                    color: #1D2832;
                                }
                            }
    
                            .title-small {
                                font-size: 14px;
                                display: block;
                                color: $color-0;

                                @include media-breakpoint-down(sm) {
                                    color: #837B72;
                                }
                            }
                        }
                        
                        .icon {
                            max-height: 22px;
                            cursor: pointer;
    
                            &.filled ,
                            &.linear {
                                opacity: 1;
                                width: auto;
                                transition: 0.2s;
                            }
                        }
                    }
    
                    &.bottom-info {
                        justify-content: space-between;
    
                        .feature {
                            margin-right: 30px;

                            .icon {
                                margin-right: 7px;
                                max-height: 18px;
                                vertical-align: text-top;
                            }
    
                            .info-feature {
                                font-size: 15px;
                                font-family: $poppinsregular;
                            }
                        }
                    }
                }
    
                .features {
                    padding: 25px 10px 10px;
    
                    .text {
                        color: $color-10;
                    }
    
                    .list {
                        list-style: none;
                    }
                }
            }
        }
    }
}