.title {
    color: $color-2;
    font-family: $poppinsregular;
    line-height: normal;

    &.title-1 {
        font-size: 30px;
        line-height: 35px;

        @include media-breakpoint-down(sm) {
            font-size: 25px;
            line-height: 38px;
        }
    }

    &.title-2 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
            font-size: 20px;
        }
    }

    &.title-3 {
        font-size: 20px;
        line-height: 30px;

        @include media-breakpoint-down(sm) {
            font-size: 18px;
            line-height: 27px;
        }

        span {
            font-family: $poppinssemibold;
        }
    }

    &.title-4 {
        font-size: 18px;
        line-height: 27px;
    }

    &.title-5 {
        font-family: $poppinssemibold;
        font-size: 16px;
        line-height: 25px;
    }

    &.title-6 {
        font-family: $poppinssemibold;
        font-size: 20px;
        line-height: 30px;
    }

    &.title-7 {
        font-family: $poppinssemibold;
        font-size: 18px;
        line-height: 27px;
    }

    &.title-8 {
        font-size: 16px;
        line-height: 25px;
    }

    &.title-9 {
        font-family: $cardo_regular;
        font-size: 24px;
        line-height: 40px;
        text-transform: uppercase;
        margin: 0;

        @include media-breakpoint-down(sm) {
            font-size: 20px;
            line-height: 28px;
        }
    }

    &.title-10 {
        font-family: $cardo_regular;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
        margin: 0;

        @include media-breakpoint-down(sm) {
            font-size: 24px;
            line-height: 38px;
        }
    }
}
