.blog-section {
    .content-post {
        border-bottom: 1px solid $color-3;
        padding-bottom: 20px;
        margin-bottom: 50px;
        
        h2, h3 {
            font-family: $poppinssemibold;
            font-size: 20px;
            line-height: 30px;

            strong {
                font-weight: normal;
            }
        }

        .content-category {
            margin-top: 50px;
        }
    }

    img {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }

    .icon {
        border-radius: 0;
        width: auto;
    }

    .content-category {
        .text {
            color: $color-10;
            margin-right: 40px;
        }

        .icon {
            max-height: 16px;
            margin-right: 5px;
        }
    }

    .items {
        .item {
            &.item-article {
                .img-post {
                    width: 100%;
                    height: 350px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 10px;
                    margin-bottom: 20px;

                    @include media-breakpoint-down(sm) {
                        height: 250px;
                    }
                }
            }
        }
    }

    .blog-block {
        background-color: $color-4;
        padding: 80px 0;

        @include media-breakpoint-down(sm) {
            padding: 45px 0 25px;
        }

        .content {
            border-top: none;
            padding: 0;
        }

        .title-2 {
            text-align: center;
            margin-bottom: 30px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 10px;
            }
        }
    }

    .block-filters {
        margin-bottom: 20px;
    }

    .social-media {
        display: flex;
        list-style: none;
        margin-right: 25px;

        @include media-breakpoint-down(sm) {
            justify-content: center;
            margin-bottom: 45px;
        }

        .list-item {
            padding: 0 10px;

            .list-link {
                display: block;
            }

            &:first-child {
                padding-left: 0;
            }
        }
    }
}