@font-face {
    font-family: 'poppinsblack';
    src: url('./fonts/poppins-black-webfont.woff2') format('woff2'),
         url('./fonts/poppins-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsblack_italic';
    src: url('./fonts/poppins-blackitalic-webfont.woff2') format('woff2'),
         url('./fonts/poppins-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsbold';
    src: url('./fonts/poppins-bold-webfont.woff2') format('woff2'),
         url('./fonts/poppins-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsbold_italic';
    src: url('./fonts/poppins-bolditalic-webfont.woff2') format('woff2'),
         url('./fonts/poppins-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsextrabold';
    src: url('./fonts/poppins-extrabold-webfont.woff2') format('woff2'),
         url('./fonts/poppins-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsextrabold_italic';
    src: url('./fonts/poppins-extrabolditalic-webfont.woff2') format('woff2'),
         url('./fonts/poppins-extrabolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsextralight';
    src: url('./fonts/poppins-extralight-webfont.woff2') format('woff2'),
         url('./fonts/poppins-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsextralight_italic';
    src: url('./fonts/poppins-extralightitalic-webfont.woff2') format('woff2'),
         url('./fonts/poppins-extralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsitalic';
    src: url('./fonts/poppins-italic-webfont.woff2') format('woff2'),
         url('./fonts/poppins-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinslight';
    src: url('./fonts/poppins-light-webfont.woff2') format('woff2'),
         url('./fonts/poppins-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinslight_italic';
    src: url('./fonts/poppins-lightitalic-webfont.woff2') format('woff2'),
         url('./fonts/poppins-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsmedium';
    src: url('./fonts/poppins-medium-webfont.woff2') format('woff2'),
         url('./fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsmedium_italic';
    src: url('./fonts/poppins-mediumitalic-webfont.woff2') format('woff2'),
         url('./fonts/poppins-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsregular';
    src: url('./fonts/poppins-regular-webfont.woff2') format('woff2'),
         url('./fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinssemibold';
    src: url('./fonts/poppins-semibold-webfont.woff2') format('woff2'),
         url('./fonts/poppins-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinssemibold_italic';
    src: url('./fonts/poppins-semibolditalic-webfont.woff2') format('woff2'),
         url('./fonts/poppins-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsthin';
    src: url('./fonts/poppins-thin-webfont.woff2') format('woff2'),
         url('./fonts/poppins-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsthin_italic';
    src: url('./fonts/poppins-thinitalic-webfont.woff2') format('woff2'),
         url('./fonts/poppins-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cardo_regular';
    src: url('./fonts/Cardo-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cardo_bold';
    src: url('./fonts/Cardo-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
