.form-group {
    margin-bottom: 20px;
    position: relative;

    &.black-group {
        .form-control {
            background-color: $color-2;
            color: $color-1;   
            
            &.custom-select {
                background-image: url(../../images/icons/down-arrow-white.png);
            }
        }
    }

    &.normal-group {
        .form-control {
            padding: 10px 20px;
        }

        .label {
            color: #9A9A9A;
        }
    }

    .label-control {
        font-size: 12px;
        position: absolute;
        top: 7px;
        left: 20px;
    }

    .form-control {
        color: $color-2;
        font-size: 14px;
        background-color: transparent;
        height: 55px;
        padding: 22px 20px 5px;
        border-radius: 10px;
        background-color: $color-0;

        &::placeholder {
            font-size: 15px;
            color: $color-7 !important;
        }
        
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            font-size: 15px;
            color: $color-7 !important;
        }
        
        &::-moz-placeholder { /* Firefox 19+ */
            font-size: 15px;
            color: $color-7 !important;
        }
        
        &:-ms-input-placeholder { /* IE 10+ */
            font-size: 15px;
            color: $color-7 !important;
        }
        
        &:-moz-placeholder { /* Firefox 18- */
            font-size: 15px;
            color: $color-7 !important;
        }

        &.small {
            height: 32px;
            border-radius: 10px;
            padding: 8px 20px;

            &::placeholder {
                font-size: 12px;
            }
            
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                font-size: 12px;
            }
            
            &::-moz-placeholder { /* Firefox 19+ */
                font-size: 12px;
            }
            
            &:-ms-input-placeholder { /* IE 10+ */
                font-size: 12px;
            }
            
            &:-moz-placeholder { /* Firefox 18- */
                font-size: 12px;
            }
        }

        &.custom-select {
            background-image: url(../../images/icons/down-arrow.png);
            background-repeat: no-repeat;
        }
    }

    textarea.form-control {
        height: 90px;
    }
}

.custom-control {
    &.custom-radio {
        width: 50%;
        padding: 0;
        margin: 0;

        .custom-control-label {
            width: 100%;
            color: $color-2;
            background-color: transparent;
            padding: 15px 30px;
            display: block;
            border-radius: 0 10px 10px 0;
            text-align: center;

            &:after {
                opacity: 0 !important;
                left: 0 !important;
            }

            &:before {
                opacity: 0 !important;
                left: 0 !important;
            }
        }

        .custom-control-input:checked ~ .custom-control-label {
            color: $color-1;
            background-color: $color-2;
            border-radius: 10px;
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            opacity: 0;
        }

        .custom-control-input:checked ~ .custom-control-label::after {
            opacity: 0;
        }
    }

    &.custom-checkbox {
        .custom-control-label {
            font-size: 14px;
            line-height: 30px;

            &:before {
                background-color: transparent;
                border: 2px solid $color-3;
                width: 20px;
                height: 20px;
            }
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            background-color: $color-3;
        }

        .custom-control-input:checked ~ .custom-control-label::after {
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            background-color: transparent;
            border-bottom: 1px solid $color-2;
            border-right: 1px solid $color-2;
            transform: rotate(45deg);
            position: absolute;
            left: -18px;
            top: 8px;
        }

        .custom-control-input:focus ~ .custom-control-label::before {
            box-shadow: 0 0 0 1px $color-3, 0 0 0 0.2rem rgba(0, 0, 0, 0.1);
        }
    }

    &.small-checkbox {
        margin-bottom: 15px;
        text-align: left;
    
        .custom-control-label {
            font-size: 12px;
            line-height: 18px;
            color: $color-7;
            padding: 5px 0 0 7px;
    
            a {
                font-size: 12px;
                color: $color-2;
            }
        }
    }
}

.error-message {
    position: absolute;
    font-size: 12px;
    color: $color-9;
    bottom: -18px;
    left: 0;
}

.status-message {
    position: absolute;
    top: -20px;
}