.main-section {
    height: calc(100vh - 80px);
    
    .swiper-container {
        .swiper-pagination {
            &.swiper-pagination-bullets {
                display: flex;
                bottom: 0;
                left: 50%;
                margin-left: 160px;

                @include media-breakpoint-down(sm) {
                    left: auto;
                    margin-left: 0;
                    justify-content: center;
                }
        
                .swiper-pagination-bullet {
                    color: #697480;
                    background-color: #19222A;
                    width: 40px;
                    height: 40px;
                    opacity: 1;
                    border-radius: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
        
                    &.swiper-pagination-bullet-active {
                        color: #FFFFFF;
                        background-color: #D88B39;
                    }
                }
            }
        }
    }
}

.featured-categories {
    padding: 80px 0;

    .block {
        text-align: center;

        .title {
            margin-bottom: 10px;
        }

        .text {
            color: #7B7B7B;
            margin-bottom: 30px;
        }
    }

    .block-categories {
        .category {
            height: 367px;
            border-radius: 4px;
            display: flex;
            align-items: flex-end;
            padding: 20px;
            position: relative;
            overflow: hidden;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            &:after {
                content: '';
                display: block;
                background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .text {
                color: $color-0;
                margin: 0;
                position: relative;
                padding-left: 20px;
                z-index: 1;

                .icon {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -6px;
                }
            }
        }
    }
}
.featured-properties {
    padding: 90px 0 60px;
    text-align: center;

    @include media-breakpoint-down(sm) {
        padding: 65px 0 60px;
    }

    .text {
        margin-bottom: 30px;
    }
    
    .MuiBox-root {
        padding: 0;
    }

    .swiper-container {
        padding-bottom: 50px;

        .swiper-button-next,
            .swiper-button-prev {
                width: 30px;
                height: 30px;
                border-radius: 10px;
                border: 2px solid #939393;
                top: auto;
                bottom: 0;
                left: 50%;
                z-index: 9;

                &:after {
                    content: '';
                    display: block;
                    width: 10px;
                    height: 10px;
                    background-color: transparent;
                    border-top: 2px solid #939393;
                    border-right: 2px solid #939393;
                    top: 50%;
                    margin-top: -5px;
                    left: 50%;
                    margin-left: -7px;
                    position: absolute;
                }

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            .swiper-button-next {
                margin-left: 0px;
                background-color: $color-1;
                border-color: $color-1;

                &:after {
                    transform: rotate(45deg);
                    border-color: $color-0;
                }
            }

            .swiper-button-prev {
                margin-left: -40px;

                &:after {
                    transform: rotate(-135deg);
                    margin-left: -4px;
                }
            }
    }
}

.secondary-banner {
    padding: 0 0 80px;

    @include media-breakpoint-down(sm) {
        padding: 0 0 60px;
    }

    .item {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 80px;
        border-radius: 4px;

        @include media-breakpoint-down(sm) {
            padding: 60px 20px;
            text-align: center;
        }

        .title,
        .text {
            color: $color-0;
        }

        .text-yellow {
            color: #D88B39;
            margin-bottom: 10px;
        }
    }
}

.searches-block {
    text-align: center;
    padding: 60px 0;

    @include media-breakpoint-down(sm) {
        padding: 40px 0;
    }

    .MuiBox-root {
        padding: 0;
    }

    .words-block {
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-down(sm) {
            font-size: 12px;
            display: block;
        }

        &.popular-words {
            .btn {
                width: auto;

                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                    width: 100%;
                    margin: 10px 0;
                    padding: 15px 10px;
                }
            }
        }

        .btn {
            width: calc(100% / 3 - 30px);
            margin-right: 30px;

            @include media-breakpoint-down(sm) {
                font-size: 14px;
                width: 100%;
            }
        }

        .btn-3 {
            font-size: 14px;
            color: #939393;
            margin: 10px;
            border-color: $color-3;
            padding: 15px 30px;
            
            &:hover {
                background-color: $color-0;
            }
        }

        &.interest-block {
            text-align: left;

            .btn-arrow {
                display: block;
                background-color: #f5f5f5;
                white-space: inherit;
                color: $color-2;
                text-transform: none;
                padding: 30px;
                margin-bottom: 10px;

                @include media-breakpoint-down(sm) {
                    padding: 14px;
                }

                span {
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        background-color: transparent;
                        border-top: 1px solid $color-1;
                        border-right: 1px solid $color-1;
                        width: 7px;
                        height: 7px;
                        position: absolute;
                        top: 50%;
                        margin-top: -5px;
                        left: -20px;
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }
}

.info-ad {
    padding: 100px 0 0;
    position: relative;

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    .description {
        margin-bottom: 100px;
        padding-right: 30px;

        @include media-breakpoint-down(md) {
            margin-bottom: 50px;
        }

        .title {
            margin-bottom: 35px;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    
        .block {
            .item {
                display: flex;
                padding-bottom: 30px;
                position: relative;

                &:last-child {
                    padding-bottom: 50px;
                }

                .icon {
                    max-height: 25px;
                    margin-top: -4px;
                }
    
                .item-info {
                    padding-left: 15px;
    
                    .text {
                        color: #7B7B7B;
                        margin-bottom: 4px;
    
                        &:first-child {
                            color: #1D2832;
                        }
                    }
                }
            }
        }
    
        .btn-1 {
            .icon {
                vertical-align: text-bottom;
                margin-right: 10px;
            }
            
            @include media-breakpoint-down(sm) {
                margin: 0 auto;
                max-width: 190px;
                display: block;
            }
        }
    }

    .img-ad {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 800px;
        width: 60%;

        @include media-breakpoint-down(lg) {
            max-width: 700px;
        }

        @include media-breakpoint-down(md) {
            position: relative;
            display: block;
            margin: 0 0 0 auto;
            width: 100%;
        }
    }
}

.blog-block {
    .content {
        border-top: 2px solid #e6e6e6;
        padding: 70px 0;

        @include media-breakpoint-down(sm) {
            padding: 40px 0;
        }
        
        .title-block {
            .text {
                margin-bottom: 30px;
            }

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        .blog-item {
            height: 275px;
            display: block;
            border-radius: 10px;
            overflow: hidden;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 30px;
            
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }

            > div {
                padding: 35px;
                background-color: rgba(0,0,0,0.5);
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: flex-end;
                height: 100%;
            }

            .title {
                color: $color-0;
                font-size: 20px;
                line-height: 1.5em;
            }

            span {
                color: $color-1;
                text-transform: uppercase;
                font-size: 14px;
            }
        }

        .social-media {
            .list {
                list-style: none;
                display: flex;

                .list-item {
                    padding-right: 50px;

                    @include media-breakpoint-down(sm) {
                        padding-right: 20px;
                    }

                    .list-link {
                        display: flex;
                        align-items: center;

                        .icon {
                            max-height: 22px;
                            margin-right: 10px;
                        }

                        &.instagram {
                            color: #ec3bb1;
                        }

                        &.facebook {
                            color: #0090ff;
                        }

                        &.youtube {
                            color: #f85b59;
                        }
                    }
                }
            }
        }
    }
}

.newsletter-section {
    background-color: $color-1;
    padding: 30px 0;
    position: relative;

    .info {
        .title,
        .text {
            margin: 0;

            span {
                font-family: $poppinsregular;
                display: block;

                @include media-breakpoint-down(sm) {
                    display: inline;
                }
            }

            @include media-breakpoint-down(sm) {
                font-size: 15px;
                margin-bottom: 15px;
            }
        }
    }

    .form {
        .form-group {
            position: relative;
            margin: 0;

            @include media-breakpoint-down(sm) {
                margin-bottom: 10px;
            }

            .label-control {
                font-size: 12px;
                position: absolute;
                top: 7px;
                left: 22px;
            }

            .form-control {
                font-size: 16px;
                border: 2px solid $color-0;
                background-color: transparent;
                height: 55px;
                padding: 20px 20px 5px;

                &::placeholder {
                    color: #cf9800 !important;
                }
                
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: #cf9800 !important;
                }
                
                &::-moz-placeholder { /* Firefox 19+ */
                    color: #cf9800 !important;
                }
                
                &:-ms-input-placeholder { /* IE 10+ */
                    color: #cf9800 !important;
                }
                
                &:-moz-placeholder { /* Firefox 18- */
                    color: #cf9800 !important;
                }
            }
        }

        .custom-control {
            &.small-checkbox {
                margin-top: 10px;
                margin-bottom: 0;

                .custom-control-label {
                    color: $color-2;
                    padding: 3px 0 0 0;

                    &:before {
                        border-color: $color-2;
                        width: 15px;
                        height: 15px;
                    }

                    &:after {
                        width: 5px;
                        height: 5px;
                        left: -19px;
                    }
                }

                .custom-control-input:checked ~ .custom-control-label::before {
                    background-color: transparent;
                }
            }
        }

        .btn {
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }
}
