// Colors
$color-0: #ffffff;
$color-1: #ffd300;
$color-2: #1d2832;
$color-3: #cecece;
$color-4: #f3f3f3;
$color-5: #3ca5ec;
$color-6: #4ec43c;
$color-7: #929292;
$color-8: #f85b59;
$color-9: #ef1d4d;
$color-10: #FFBB00;

// Fonts
$poppinsblack: 'poppinsblack', sans-serif;
$poppinsblack_italic: 'poppinsblack_italic', sans-serif;
$poppinsbold: 'poppinsbold', sans-serif;
$poppinsbold_italic: 'poppinsbold_italic', sans-serif;
$poppinsextrabold: 'poppinsextrabold', sans-serif;
$poppinsextrabold_italic: 'poppinsextrabold_italic', sans-serif;
$poppinsextralight: 'poppinsextralight', sans-serif;
$poppinsextralight_italic: 'poppinsextralight_italic', sans-serif;
$poppinsitalic: 'poppinsitalic', sans-serif;
$poppinslight: 'poppinslight', sans-serif;
$poppinslight_italic: 'poppinslight_italic', sans-serif;
$poppinsmedium: 'poppinsmedium', sans-serif;
$poppinsmedium_italic: 'poppinsmedium_italic', sans-serif;
$poppinsregular: 'poppinsregular', sans-serif;
$poppinssemibold: 'poppinssemibold', sans-serif;
$poppinssemibold_italic: 'poppinssemibold_italic', sans-serif;
$poppinsthin: 'poppinsthin', sans-serif;
$poppinsthin_italic: 'poppinsthin_italic', sans-serif;
$cardo_regular: 'cardo_regular', sans-serif;
$cardo_bold: 'cardo_bold', sans-serif;
